import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'

const app = createApp(App)
installElementPlus(app)
app.use(store).use(router).mount('#app')

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    
    if (to.matched.length !== 0) {
        if (to.name) {
            document.title = to.name+' | Syugr'
        }
        next()
    } 
    else {
    next({ path: '/404' })
    }
  })