<template>
    <div class="home">
        <h1>Welcome to Syugr!</h1>
        <h3>This page is under construction now. </h3>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {};
    },
    components: {}
}
</script>
