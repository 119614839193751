<template>
  <div class="about">
    <h1>This will be an about page.</h1>
    <p :key='index' v-for="(item, index) in items">
      {{item.text+(index+1)}}
    </p>
  </div>
</template>

<script>
export default {
    name: 'About',
    data() {
      return {
        items: Array(100).fill({
        text:'Testing message '
      })
      };
    },
    methods: {}
}
</script>