<template>
  <el-scrollbar ref="scroll"  :style="pageHeight" always>
    <el-container class="home-container" :style="containerHeight">
      <el-header :style="{ boxShadow: showBoxShadow ? '0px 3px 8px -3px gray' : '0px 3.5px 0px -3px gray' }">
        <div class="ttl">
          <router-link to="/">
            <img id="weblogo" src="./assets/syugr_logo_highlight.svg">
          </router-link>
          <router-link to="/">
            <span id="webtitle">Syugr</span>
          </router-link>
        </div>
        <el-menu 
          :default-active="$route.path" 
          class="el-menu-demo" 
          mode="horizontal"
          menu-trigger="click"
          :background-color="hColor"
          text-color="#000"
          active-text-color="#000"
          @open="unfoldMenu"
          @close="foldMenu"
          @select="foldMenu"
          router>
            <el-menu-item :key="key" v-for="(item, key) in spreadNavItems" :index="item.navPath">
              {{item.name}}
            </el-menu-item>
            <el-submenu v-if="Object.keys(foldedNavItems).length === 0&#63;false:true" style="color: black" index="">
              <template #title>
                <transition 
                name="spin" 
                mode="out-in" 
                v-on:after-leave="afterLeave">
                  <i v-show="submenuShow" :class="menuClass" style="color: black"></i>
                </transition>
              </template>
              <el-menu-item
                :key="key"
                v-for="(item,key) in foldedNavItems"
                :index="item.navPath"
                style="text-align: center">
                {{item.name}}
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/"><i class="el-icon-s-home" style="color: black"></i></el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view v-slot="{ Component }">
          <transition name="slide" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </el-main>
      <el-footer>
        <div>
          <span>Copyright © Syugr. All Rights Reserved.</span>
        </div>
      </el-footer>
    </el-container>
  </el-scrollbar>
</template>

<!--脚本-->
<script>
export default {
  name: 'App',
  data() {
    return {
      hColor: '#fff',
      screenWidth: document.body.clientWidth,
      screenHeight: window.innerHeight,
      scrollPosition: 0,
      navItems: [
        {
          name: 'Home',
          navPath: '/',
          index: '1',
        },
        {
          name: 'Navigation 1',
          navPath: '/Nav1',
          index: '2',
        },
        {
          name: 'Navigation 2',
          navPath: '/Nav2',
          index: '3',
        },
        {
          name: 'About',
          navPath: '/About',
          index: '4',
        }
      ],
      submenuShow: true,
      isFolded: true
    }
  },
  mounted () {
    window.onresize = () => {
      this.screenWidth = document.body.clientWidth
      this.screenHeight = window.innerHeight
      // console.log(this.screenHeight)
    },
    window.onload = function() {
      document.addEventListener('touchstart', function(e) {
        console.log("1",e.touches.length)
        if (e.touches.length > 1) {
          e.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function(e) {
        console.log("2")
        e.preventDefault()
      })
    }
    this.$nextTick(() => {
      const scrollbar = this.$refs.scroll;

      scrollbar.$refs.wrap.addEventListener('scroll', () => {
        this.scrollPosition = scrollbar.$refs.wrap.scrollTop
        // console.log(this.scrollPosition);
      });
    })
  },
  methods: {
    unfoldMenu() {
      if(this.isFolded==true){
        this.submenuShow=false
      }
    },
    foldMenu() {
      if(this.isFolded==false){
        this.submenuShow=false
      }
    },
    afterLeave() {
      if(this.isFolded==true){
        this.isFolded=false
        this.submenuShow=true
      }
      else {
        this.isFolded=true
        this.submenuShow=true
      }
    }
  },
  watch: {
    screenWidth(newValue) {
    // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = newValue;
        this.timer = true;
        setTimeout(() => {
          //console.log(this.screenWidth);
          this.timer = false;
        }, 400);
      }
    },
    screenHeight(newValue) {
    // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenHeight = newValue;
        this.timer = true;
        setTimeout(() => {
          //console.log(this.screenWidth);
          this.timer = false;
        }, 400);
      }
    }
  },
  computed: {
    spreadNavItems: function(){
      return this.screenWidth > 600 ? this.navItems.slice(1) : {};
    },
    foldedNavItems: function(){
      return this.screenWidth <= 600 ? this.navItems.slice(1) : {};
    },
    menuClass: function(){
      return this.isFolded? 'el-icon-s-fold':'el-icon-close'
    },
    // hBgColor: function() {
    //   return 'background-color: '+this.hColor
    // },
    pageHeight: function() {
      return 'height: '+this.screenHeight.toString()+'px'
    },
    containerHeight: function() {
      return 'min-height: '+this.screenHeight.toString()+'px'
    },
    showBoxShadow: function() {
      return this.scrollPosition>10
    }
  }
}
</script>


<!--样式表-->
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.el-scrollbar {
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-container{
  /* min-height: 100%; */
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* flex: auto; */
  /* margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px; */
}

.el-header {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 60px;
  user-select: none;
  background-color: #fff;
  transition: box-shadow 0.2s ease-in-out;
  /* box-shadow: 0px 0px 0px 0px; */
  /* box-shadow: 0px 3px 6px -3px gray; */
  /* border-bottom: 1px solid gray; */
}

.el-header div {
  display: flex;
  align-items: center;
}

#weblogo {
  max-height: 48px;
}

#webtitle {
  color: black;
  font-size: 28px;
}

.el-header div span {
  color: white;
  margin-left: 10px;
}

.ttl span {
  font-size: 28px;
  font-weight: 500;
}

.el-menu {
  /* display: flex; */
  font-size: 20px;
  align-items: center;
}

/* .el-menu-demo {
  box-shadow: 0px 0px 0px 0px;
  border-bottom: 1px solid gray;
}

.el-menu-item {
  box-shadow: 0px 0px 0px 0px;
  border-bottom: 1px solid gray;
} */

.el-submenu {
  font-size: 20px;
}

/* .el-main {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
} */


.el-main {
  margin-top: 60px;
}

.el-footer {
  background-color: #E4E7ED;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 100%;
  align-items: center;
}

.el-footer div {
  align-items: center;
  font-size: small;
}

a {
  text-decoration: none;
}
 
.router-link-active {
  text-decoration: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-10%);
}

.spin-enter {
  opacity: 0;
  transform: rotateY(-90deg);
}
.spin-enter-active {
  transition: all 0.15s;
}
.spin-enter-to {
  opacity: 1;
  transform: rotateY(0);
}
.spin-leave-active {
  transition: all 0.15s;
}
.spin-leave-to {
  opacity: 0;
  transform: rotateY(90deg);
}

/* 删除element plus中submenu的小箭头 */
.el-submenu__title .el-submenu__icon-arrow{
    display: none;
}

</style>
