<template>
  <div class="not-found">
    <div>
      <h1>404</h1>
      <h1>This page does not exit.</h1>
    </div>
    <div>
      <div id="three"></div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import sugarTexture from '@/assets/sugar_cube_texture.jpg'
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
var scene,mesh
export default {
    name: 'NotFound',
    data() {
        return {
          // scene: null,
          // mesh: null,
          renderer: null,
          camera: null,
          clock: null,
          delta: null,
          interval: null,
          id: null
        };
    },
    methods: {
      initThree() {
        if (this.id) {
            cancelAnimationFrame(this.id)
        }
        this.clock = new THREE.Clock()
        this.delta = 0
        // 30 fps
        this.interval = 1 / 30

        scene = new THREE.Scene()
        scene.background = new THREE.Color('#fff')
        let container = document.querySelector('#three')
        this.renderer = new THREE.WebGLRenderer({ container, antialias: true })
        this.renderer.setSize(container.clientWidth,container.clientHeight)
        this.camera = new THREE.PerspectiveCamera(
          15,
          container.clientWidth / container.clientHeight,
          0.1,
          10000
        )
        this.camera.position.z = 50

        scene.add(this.camera)


        // Create a light, set its position, and add it to the scene.
        const color = 0xFFFFFF;
        const intensity = 200000;
        var light = new THREE.PointLight(color, intensity);
        light.position.set(10,30,200);
        // const light = new THREE.AmbientLight(color, intensity);
        scene.add(light);

        // Add OrbitControls so that we can pan around with the mouse.
        // var controls = new OrbitControls(camera, renderer.domElement);

        // Add axes
        // var axes = new THREE.AxesHelper(50);
        // scene.add( axes );
        // const textureLoader = new THREE.TextureLoader();
        const texture = new THREE.TextureLoader().load(sugarTexture);
				texture.colorSpace = THREE.SRGBColorSpace;

        var geometry = new THREE.BoxGeometry(5,5,5);
        var cubeMaterial = new THREE.MeshBasicMaterial({map:texture});

        // const cubecolor = new THREE.Color('white');
        // cubeMaterial.color=cubecolor

        mesh = new THREE.Mesh( geometry, cubeMaterial );
        scene.add( mesh );

        
        container.appendChild(this.renderer.domElement)
      },

      animate() {
        this.id = requestAnimationFrame( this.animate );
        // controls.update();
        this.delta += this.clock.getDelta();

        if (this.delta  > this.interval) {

            // The draw or time dependent code are here
            mesh.rotation.x+=0.01
            mesh.rotation.y+=0.01
            
            this.renderer.render( scene, this.camera );

            this.delta = this.delta % this.interval;
        }
      }
    },
    mounted() {
      this.initThree()
      this.animate()
    }
}
</script>

<style scoped>
.not-found {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#three {
  width: 384px;
  height: 216px;
}
</style>